import EBT from "../../images/carousel/EBT.png";
import OTC from "../../images/carousel/OTC.png";
import WIC from "../../images/carousel/WIC.png";
import RIA from "../../images/carousel/RIA.png";
import HOURS from "../../images/carousel/HOURS.png";

import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/autoplay";
import "swiper/css/effect-creative";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const slides = [
  { id: "Ria", src: { RIA } },
  { id: "EBT", src: { EBT } },
  { id: "OTC", src: { OTC } },
  { id: "WIC", src: { WIC } },
  { id: "Hours", src: { HOURS } },
];

export default function Carousel() {
  return (
    <>
      <Swiper
        centeredSlides={true}
        autoplay={{
          delay: 7000,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        slidesPerView={1}
        loop={true}
        className="mySwiper max-w-[1200px]"
        style={{
          "--swiper-navigation-color": "#404040",
          "--swiper-navigation-size": "36px",
        }}

        // Alternative with 3sec default timeout:
        //autoplay={true}
      >
        <SwiperSlide>
          <CarouselSlide image={RIA} />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselSlide image={OTC} />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselSlide image={WIC} />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselSlide image={HOURS} />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselSlide image={WIC} />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

function CarouselSlide(props) {
  return (
    <div
      {...props}
      className="flex items-center gap-2 md:gap-4 max-md:flex-row text-black px-6 py-1.5  font-[sans-serif] justify-center"
    >
      <div className=" md:pb-1.5">
        <img src={props.image} className="w-[1200px]" />
      </div>
    </div>
  );
}

const RiaSlide = () => {
  return <CarouselSlide image={RIA} />;
};
const OTCSlide = () => {
  return <CarouselSlide image={OTC} />;
};

const EBTSlide = () => {
  return <CarouselSlide image={EBT} />;
};

const WICSlide = () => {
  return <CarouselSlide image={WIC} />;
};

const HoursSlide = () => {
  return <CarouselSlide image={HOURS} />;
};
