import "./App.css";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Layout/Footer";
import ContactUs from "./pages/Contact";
import Home from "./pages/Home";
import Returns from "./pages/ReturnPolicy";

import Test from "./pages/Test";

import Sales from "./pages/Sales";

import CreatePDF from "./components/flyer/flyerGPT";

import BannerSlides from "./components/Banner/BannerSlide";

import Nav from "./components/Layout/Nav";
function App() {
  return (
    <div className="App">
      <Nav />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/returns" element={<Returns />} />

        <Route path="/test" element={<Test />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
