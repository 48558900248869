import front from "../images/mar30/front.jpg";
import back from "../images/mar30/back.jpg";

import Carousel from "../components/Carousel/carousel";

export default function Home() {
  return (
    <div className="w-full min-h-full box-border flex-col gap-4 justify-center align-center items-center md:mx-2 my-2 mb-16">
      <Ad2Pages />
      <Carousel />
    </div>
  );
}

function Ad2Pages() {
  return (
    <div className="box-border flex flex-row items-center md:flex-row gap-4 p-4 justify-center md:items-start ">
      <div className="w-screen px-2">
        <div className="flex justify-center h-fit w-fit">
          <img src={front} className="h-auto block w-screen object-contain" />
        </div>
      </div>
      <div className="w-screen">
        <div className="flex justify-center h-fit w-fit">
          <img src={back} className="h-auto block w-screen object-contain" />
        </div>
      </div>
    </div>
  );
}
